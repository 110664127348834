import { ref, watch, Ref, computed } from "vue";
import { IField } from "~/entities/field";
import { IItem } from "~/entities/item";
import { ItemValidationErrors } from "../../model/interfaces";
import { validateRefactoredItem } from "../../model/validate-item";

export function useItemValidation(item: Ref<IItem | null>, fields: Ref<IField[]>) {
  const isValid = ref<boolean>(true);
  const errors = ref<ItemValidationErrors>({});

  watch(
    () => [item.value, fields.value],
    ([newItem, newFields]) => {
      if (!newItem) return;

      const validationErrors = validateRefactoredItem(
        newItem as IItem,
        newFields as IField[],
      );

      errors.value = validationErrors;
      isValid.value = !Object.keys(validationErrors).length;
    },
    {
      immediate: true,
      deep: true,
    },
  );

  return {
    isValid: computed(() => isValid.value),
    errors: computed(() => errors.value),
  };
}
